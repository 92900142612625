import React from 'react';

import { Layout } from './Layout/Layout';

export const App = () => {
  return (
    <>
      <Layout />
    </>
  );
};
