import Bristol1 from './Bernardazzi/Bristol/1.jpeg';
import Bristol2 from './Bernardazzi/Bristol/2.jpeg';

import Garbuzov1 from './Bernardazzi/Garbuzov/1.jpeg';
import Garbuzov2 from './Bernardazzi/Garbuzov/2.jpeg';

import Levit1 from './/Bernardazzi/Levit/1.jpeg';
import Levit2 from './/Bernardazzi/Levit/2.jpeg';

import Petrokokino1 from './/Bernardazzi/Petrokokino/1.jpeg';
import Petrokokino2 from './/Bernardazzi/Petrokokino/2.jpeg';
import Petrokokino3 from './/Bernardazzi/Petrokokino/3.jpeg';
import Petrokokino4 from './/Bernardazzi/Petrokokino/4.jpeg';
import Petrokokino5 from './/Bernardazzi/Petrokokino/5.jpeg';

import Hydrotherapy_Valik1 from './/Bernardazzi/Hydrotherapy_Valik/1.jpeg';
import Hydrotherapy_Valik2 from './/Bernardazzi/Hydrotherapy_Valik/2.jpeg';

import Grinchenko1 from './/Bernardazzi/Grinchenko/1.jpeg';
import Grinchenko2 from './/Bernardazzi/Grinchenko/2.jpeg';

import Doppelmeistr1 from './/Bernardazzi/Doppelmeistr/1.jpeg';
import Doppelmeistr2 from './/Bernardazzi/Doppelmeistr/2.jpeg';

import Mavrokordato1 from './/Bernardazzi/Mavrokordato/1.jpeg';
import Mavrokordato2 from './/Bernardazzi/Mavrokordato/2.jpeg';

import Asvadurov1 from './/Bernardazzi/Asvadurov/1.jpeg';

import New_exchange1 from './/Bernardazzi/New_exchange/1.jpeg';
import New_exchange2 from './/Bernardazzi/New_exchange/2.jpeg';
import New_exchange3 from './/Bernardazzi/New_exchange/3.jpeg';
import New_exchange4 from './/Bernardazzi/New_exchange/4.jpeg';
import New_exchange5 from './/Bernardazzi/New_exchange/5.jpeg';
import New_exchange6 from './/Bernardazzi/New_exchange/6.jpeg';

import Mutual_Credit_Society1 from './/Bernardazzi/Mutual_Credit_Society/1.jpeg';
import Mutual_Credit_Society2 from './/Bernardazzi/Mutual_Credit_Society/2.jpeg';
import Mutual_Credit_Society3 from './/Bernardazzi/Mutual_Credit_Society/3.jpeg';
import Mutual_Credit_Society4 from './/Bernardazzi/Mutual_Credit_Society/4.jpeg';

import Reformed_Church1 from './/Bernardazzi/Reformed_Church/1.jpeg';
import Reformed_Church2 from './/Bernardazzi/Reformed_Church/2.jpeg';
import Reformed_Church3 from './/Bernardazzi/Reformed_Church/3.jpeg';
import Reformed_Church4 from './/Bernardazzi/Reformed_Church/4.jpeg';

import English_club1 from './Torricelli/English_club/1.jpeg';
import English_club2 from './Torricelli/English_club/2.jpeg';

import Kamo1 from './Torricelli/Kamo/1.jpeg';
import Kamo2 from './Torricelli/Kamo/2.jpeg';

import Narolskiy1 from './Torricelli/Narolskiy/1.jpeg';
import Narolskiy2 from './Torricelli/Narolskiy/2.jpeg';
import Narolskiy3 from './Torricelli/Narolskiy/3.jpeg';
import Narolskiy4 from './Torricelli/Narolskiy/4.jpeg';

import Tolstoy1 from './Torricelli/Tolstoy/1.jpeg';
import Tolstoy2 from './Torricelli/Tolstoy/2.jpeg';
import Tolstoy3 from './Torricelli/Tolstoy/3.jpeg';
import Tolstoy4 from './Torricelli/Tolstoy/4.jpeg';

export const data1 = [
  {
    id: 'Bernardazzi',
    author: 'Бернардацци Александр Иосифович',
    authorUkrainian: 'Бернардаццi Oлександр Йоcипович',
    authorEnglish: 'Bernardazzi Alexander',
    authorDeutsch: 'Bernardazzi Alexandr',
    projectName: 'Гостиница Бристоль',
    projectNameUkrainian: 'Готель Бристоль',
    projectNameEnglish: 'Bristol Hotel',
    projectNameDeutsch: 'Hotel Bristol ',
    photos: [Bristol1, Bristol2],
    text: `В конце XIX в. архитектор А. О. Бернардацци приступил к проектированию роскошной гостиницы в стиле необарокко, которую планировалось строить по соседству с Новой Биржей. В 1899 г. строительство обоих зданий было окончено.
    
Гостиница строилась по заказу известного отеле содержателя Н. Юровского и получила имя «Бристоль». О ней с восторгом писала пресса того времени:«Эта гостиница, находящаяся в центре города против нового здания биржи, помещается в огромном, специально для этой цели отстроенном здании очень изящной архитектуры, и является самой большой в Одессе. Гостиница имеет электрическое освещение, подъёмную машину и т. п. Все номера отлично меблированы. Имеются помещения из нескольких комнат. При этой гостинице имеется зимний сад, куда подаётся обед, завтрак и т. п. Гостиница „Бристоль“ построена по плану архитектора А. О. Бернардацци и является одним из наиболее красивых зданий в Одессе. На крыше гостиницы имеется оригинально устроенная площадка, с которой открывается чудный вид на город и море.»

Еще при строительстве «Бристоль» позиционировался, как самая роскошная гостиница в городе, четырехэтажное здание которой поражало не только уровнем архитектурной изысканности, но и степенью передовых инженерных решений. Это одно из первых зданий в городе, получивших лифт и индивидуальное паровое отопление во всех номерах.

В советский период гостиница «Бристоль» была переименована в «Красную», сменила цвет стен с небесно-голубого на более соответствующий новому названию и утратила угловую декоративную башню. В целом же здание дошло до наших дней в почти неизменном состоянии, а в конце 2000-х гг. гостинице вернули историческое название.`,
    textEnglish: `At the end of the XIX century architect A. O. Bernardazzi began designing a luxurious hotel in neo-baroque style, which was planned to be built next to the New Exchange. In 1899 the construction of both buildings was completed.
     
The hotel was built by order of the well-known hotelier N.Yurovsky and was named "Bristol". The press of the time wrote about it with enthusiasm:
"This hotel, which is situated in the city center against the new building of the Stock Exchange, is located in a huge, specially built for this purpose building of very elegant architecture, and is the largest in Odessa. The hotel has electric lights, a lift machine, etc. All rooms are perfectly furnished. There are rooms of several rooms. This hotel has a winter garden where lunch, breakfast, etc. are served. The hotel "Bristol" is built according to the plan of the architect A. O. Bernardazzi and is one of the most beautiful buildings in Odessa. On the roof of the hotel there is an originally arranged platform from which there is a wonderful view of the city and sea.
    
Even at the time of construction "Bristol" was positioned as the most luxurious hotel in the city, a four-storey building which impressed not only the level of architectural sophistication, but also the degree of advanced engineering solutions. It was one of the first buildings in town to have an elevator and individual steam heating in all rooms.
   
During the Soviet period the Bristol Hotel was renamed the Red Hotel, changed its color from sky-blue to something more appropriate to its new name and lost its corner decorative tower. In general, the building has survived almost unchanged, and in the late 2000s the hotel regained its historic name.`,
    textUkrainian: `Наприкінці XIX ст. архітектор О. О. Бернардацці розпочав проектування розкішного готелю в стилі необароко, який планувалося будувати по сусідству з Новою Біржею. У 1899 р. будівництво обох будівель було закінчено.
     
Готель будувався на замовлення відомого готелі утримувача М. Юровського і отримав ім'я "Брістоль". Про нього із захопленням писала преса того часу:
"Цей готель, розташований у центрі міста проти нової будівлі біржі, міститься у величезному, спеціально для цієї мети відбудованому будинку дуже витонченої архітектури, і є найбільшим в Одесі. Готель має електричне освітлення, підйомну машину тощо. Усі номери відмінно мебльовані. Є приміщення з декількох кімнат. При цьому готелі є зимовий сад, куди подається обід, сніданок тощо. Готель "Брістоль" побудовано за планом архітектора А. О. Бернардацці, він є однією з найкрасивіших будівель в Одесі. На даху готелю є оригінально влаштований майданчик, з якого відкривається дивовижний краєвид на місто і море."
    
Ще під час будівництва "Брістоль" позиціонувався як найрозкішніший готель у місті, чотириповерхова будівля якого вражала не тільки рівнем архітектурної вишуканості, а й ступенем передових інженерних рішень. Це одна з перших будівель у місті, що отримала ліфт та індивідуальне парове опалення у всіх номерах.
   
У радянський період готель "Брістоль" було перейменовано на "Червоний", він змінив колір стін із небесно-блакитного на такий, що більше відповідав би новій назві, і втратив кутову декоративну вежу. Загалом же будівля дійшла до наших днів у майже незмінному стані, а наприкінці 2000-х рр. готелю повернули історичну назву,`,
    textDeutsch: `Ende des 20. Jahrhunderts begann der Architekt. Bernardazzi mit der Planung eines prächtigen Hotels im Neubarockstil, das in der Nähe der Neuen Börse gebaut werden sollte. 1899 wurden die beiden Gebäude fertig gestellt. Das Hotel wurde im Auftrag des bekannten Hoteliers N. Yurkovskyi gebaut und bekam den Namen Bristol.

    Die damalige Presse schrieb über das Hotel voller Begeisterung: „Dieses Hotel liegt in der Stadtmitte dem neuen Börsengebäude gegenüber. Es ist ein eigens dafür gebautes Gebäude mit der feinsten Architektur und damit das größte Hotel in Odesa. Das Hotel verfügt über elektrische Beleuchtung, einen Aufzug usw. Alle Zimmer sind hervorragend eingerichtet. Manche Räume bestehen aus mehreren Zimmern. Im Hotel gibt es einen Wintergarten, in dem Mittagessen, Frühstück etc. serviert werden. Das Hotel „Bristol“ wurde nach dem Entwurf des Architekten A. Bernardazzi gebaut und gilt als eines der schönsten Gebäude in Odesa. Auf dem Hoteldach befindet sich ein eigenartig gestalteter Platz, der eine wunderschöne Ansicht auf die Stadt und das Meer bietet“.    

Noch während der Bauarbeiten wurde „Bristol“ als das schönste Hotel der Stadt genannt. Das vierstöckige Gebäude beeindruckte nicht nur durch ihre baukünstlerische Zierlichkeit, sondern auch durch fortschrittliche technische Lösungen. Das war eines der ersten Gebäude in der Stadt, das mit einem Aufzug und der individuellen Dampfheizung in jedem Zimmer ausgestattet wurde.   

In der Sowjetzeit wurde das Hotel „Bristol“ in „Rotes Hotel“ umbenannt. Die Wandfarbe passte sich ebenfalls dem neuen Namen an. Das Hotel büßte seinen dekorativen Eckturm ein. Im Großen und Ganzen blieb das Gebäude fast unverändert bis an unsere Zeiten erhalten. Ende 2000-er Jahre bekam das Hotel seinen historischen Namen zurück.`,
  },
  {
    id: 'Bernardazzi',
    author: 'Бернардацци Александр Иосифович',
    authorUkrainian: 'Бернардаццi Oлександр Йоcипович',
    authorEnglish: 'Bernardazzi Alexander',
    authorDeutsch: 'Bernardazzi Alexandr',
    projectName: 'Доходный дом Гарбузова',
    projectNameUkrainian: 'Прибутковий будинок Гарбузова',
    projectNameEnglish: 'Profitable house of Garbuzov',
    projectNameDeutsch: 'Das Haus von Harbuzov',
    photos: [Garbuzov1, Garbuzov2],
    text: `Огромный доходный дом на углу Княжеской и Ольгиевской, дом Гарбузова, принято считать последним крупным проектом Бернардацци и единственным зданием, в архитектуре которого зодчий применил элементы модерна.

Владимир Васильевич Гарбузов — подрядчик, по проектам А. И. Бернардацци в 1901-1905 годах строил Клиники Медицинского факультета Новороссийского университета. Примерно в этот же период уложились и все основные работы по строительству собственного дома Гарбузова.

Дом изначально имел три этажа, четвертый достраивался ближе к 1910-м гг. по проекту архитектора Нестураха и стилистически выбивается из общего контекста строгой архитектуры здания.

Внутри имеется три просторных подъезда, самый интересный из которых расположен в арке проезда во двор. Лестница здесь имеет выразительную овальную форму.
`,
    textEnglish: `A huge apartment house on the corner of Knyazheskaya and Olgievskaya, the Garbuzov House, is considered to be Bernardazzi's last major project and the only building in the architecture of which the architect applied elements of Art Nouveau.

Vladimir Vasilievich Garbuzov - contractor, according to designs by A. I. Bernardazzi in 1901-1905 built clinics of the Medical Faculty of Novorossiysk University. Approximately in the same period all the major works on the construction of Garbuzov's own house took place.

The house originally had three floors, the fourth was completed closer to 1910th by the architect Nesturakh and stylistically stands out from the general context of the strict architecture of the building.

Inside there are three spacious entrances, the most interesting of which is located in the archway to the courtyard. The staircase here has an expressive oval shape.`,
    textUkrainian: `Величезний прибутковий будинок на розі Княжої та Ольгіївської, будинок Гарбузова, заведено вважати останнім великим проектом Бернардацці і єдиною будівлею, в архітектурі якої зодчий застосував елементи модерну.

Володимир Васильович Гарбузов - підрядник, за проектами А. І. Бернардацці в 1901-1905 роках будував Клініки Медичного факультету Новоросійського університету. Приблизно в цей же період вклалися і всі основні роботи з будівництва власного будинку Гарбузова.

Будинок спочатку мав три поверхи, четвертий добудовували ближче до 1910-х рр. за проектом архітектора Нестураха, і стилістично він вибивається із загального контексту суворої архітектури будівлі.

Усередині є три просторих під'їзди, найцікавіший з яких розташований в арці проїзду у двір. Сходи тут мають виразну овальну форму.`,
    textDeutsch: `Das riesengroße Miethaus, das an der Kreuzung der Straßen Kniazhenska und Olhiievsla liegt, gilt als das letzte große Projekt von Bernardazzi und das einzige Gebäude, dessen Architektur die Jugendstilelemente hat.
    
    Volodymyr Harbuzov war der Bauunternehmer und baute 1901-1905 nach den Planungen von Bernardazzi die Kliniken der medizinischen Fakultät. Im gleichen Zeitraum wurde auch der Bau des Privathauses von Harbuzov abgeschlossen.
    
    Ursprünglich hatte das Haus drei Stockwerke. Um 1910 wurde das Haus nach dem Projekt von dem Architekten Nesturakh aufgestockt und fällt nun aus dem Gesamtkonzept einer strengen Gebäudearchitektur stilistisch aus. Das Haus beherbergt drei geräumige Treppenhäuser, wobei das interessanteste davon im Einfahrtsbogen zum Innenhof liegt. `,
  },
  {
    id: 'Bernardazzi',
    author: 'Бернардацци Александр Иосифович',
    authorUkrainian: 'Бернардаццi Oлександр Йоcипович',
    authorEnglish: 'Bernardazzi Alexander',
    authorDeutsch: 'Bernardazzi Alexandr',
    projectName: 'Доходный дом Левита',
    projectNameUkrainian: 'Прибутковий будинок Левіта',
    projectNameEnglish: 'Profitable house of Levit',
    projectNameDeutsch: 'Das Haus von Levit',
    photos: [Levit1, Levit2],
    text: `Доходный дом Левита — характерный образец рядовых доходных домов, возводившихся Бернардацци в 1890-е гг. В городе таких домов несколько, композиционно и масштабно они похожи друг на друга, хотя каждый из них все же имеет набор уникальных выразительных элементов.
    
Трехэтажный дом Левита построен в классическом для историзма сочетании ренессанса и барокко, фасад строго симметричен и имеет протяженность в семь оконных осей.
    
Дом строился в 1891 г. для Эмилии Аристовны Тработти, однако еще до завершения строительства был приобретен купцом Левитом. Именно как дом Левита здание упомянуто в журнале «Зодчий» и считается первым образцом так называемого «планового проекта», к которому Бернардацци в дальнейшем возвращался еще не раз.
    
Например, этот проект использовался для возведения трех доходных домов для Петрококино.`,
    textEnglish: `The Levita Apartment House is a typical example of the row houses built by Bernardazzi in the 1890s. There are several such houses in the city; compositionally and in scale they resemble each other, although each of them still has a set of unique expressive elements.

The three-story Levita House was built in the classic combination of Renaissance and Baroque for historicism, the façade is strictly symmetrical and has a length of seven window axes.

The house was built in 1891 for Emilia Aristovna Trabotti, but was purchased by the merchant Levit before it was completed. It is as Levit's house the building is mentioned in the journal "Zodchy" and is considered the first example of the so-called "plan project", to which Bernardazzi later returned more than once.

For example, this project was used to build three tenement houses for Petrokokino.`,
    textUkrainian: `Прибутковий будинок Левіта - характерний зразок пересічних прибуткових будинків, що зводилися Бернардацці в 1890-ті рр. У місті таких будинків кілька, композиційно й масштабно вони схожі один на одного, хоча кожен із них усе ж таки має набір унікальних виразних елементів.

Триповерховий будинок Левіта побудований у класичному для історизму поєднанні ренесансу і бароко, фасад строго симетричний і має протяжність у сім віконних вісей.

Будинок зводили 1891 року для Емілії Арістовни Тработті, проте ще до завершення будівництва його придбав купець Левіт. Саме як будинок Левіта будівля згадана в журналі "Зодчий" і вважається першим зразком так званого "планового проекту", до якого Бернардацці надалі повертався ще не раз.

Наприклад, цей проєкт використовувався для зведення трьох прибуткових будинків для Петрококіно.`,
    textDeutsch: `Das Miethaus von Levit ist ein typisches Beispiel für die Reihenmiethäuser, die in den 1890-er Jahren von Bernardazzi gebaut wurden.
    
    Es gibt mehrere dieser Häuser in der Stadt. Nach ihrer Planung und Größe sehen sie ähnlich aus, obwohl jedes von ihnen über eigenartige Elemente verfügt. 

Das dreistöckige Levit-Haus stellt eine Verbindung von Renaissance und Barock dar, die typisch für die Epoche des Historismus war. Die Fassade ist streng symmetrisch und hat die Länge von sieben Fensterachsen. 

Das Haus sollte 1891 für Emilia Trabotti gebaut werden. Aber noch vor der Fertigstellung wurde es von dem Kaufmann Levit gekauft. In der Zeitschrift „Der Architekt“ wird das Gebäude als Haus von Levit erwähnt und gilt als erstes Beispiel für ein sogenanntes „Planungsprojekt“, worauf Bernardazzi später mehrmals zurückgriff.

So wurde dieses Projekt für drei Miethäuser für Petrokokino verwendet. 
`,
  },
  {
    id: 'Bernardazzi',
    author: 'Бернардацци Александр Иосифович',
    authorUkrainian: 'Бернардаццi Oлександр Йоcипович',
    authorEnglish: 'Bernardazzi Alexander',
    authorDeutsch: 'Bernardazzi Alexandr',
    projectName: 'Дома Петрококино',
    projectNameUkrainian: 'Будинки Петрококіно',
    projectNameEnglish: 'Petrokokino Houses',
    projectNameDeutsch: 'Die Häuser von Petrokokino',
    photos: [
      Petrokokino1,
      Petrokokino2,
      Petrokokino3,
      Petrokokino4,
      Petrokokino5,
    ],
    text: `Братья Петрококино — преуспевающая греческая купеческая семья, владевшая одним из самых больших состояний среди греков Одессы и основавшая первый в городе универсальный магазин. 

Здание универмага, «магазина, где есть все», находилось на Греческой в четном квартале между Ришельевской и Екатерининской и было разрушено в период войны, в 1944 г.

Архитектор Бернардацци часто выполнял заказы Петрококино и помимо универмага построил для них три доходных дома: на Маразлиевской, 12 и Троицкой, 18 и 20. 
В значительной степени отличаясь друг от друга архитектурно, все три здания основывались, как и дом Левита, на «плановом проекте» Бернардацци.
Каждый из них имеет удивительные и узнаваемые элементы.  

Дом на Маразлиевской, 12 строго симметричен и имеет по крайним осям полукруглые эркеры на уровне второго этажа, которые служат основанием ажурных лоджий третьего этажа. Внутри дома расположена просторная периметральная лестница овальной формы.

Дом на Троицкой, 18 выполнен в смешении ренессанса, неороманского стиля и бессарабско-румынских стилизаций. Помимо традиционного лепного декора на фасаде использована пестрая кафельная плитка.

Соседний дом, на Троицкой, 20, не столь самобытен стилем архитектуры, но оформлен пышнее. Фасад выдержан в стилистике необарокко, а арка с кессонированным потолком расписана трафаретными орнаментами. Внутри сохранился элегантный подъезд с периметральной лестницей и скругленными углами. 
`,
    textEnglish: `The Petrokokino brothers were a prosperous Greek merchant family that owned one of the largest fortunes among the Greeks of Odessa and founded the city's first department store. 

The department store building, "the store with everything", was located on Grecheskaya Street in the even block between Rishelievskaya and Ekaterininskaya Street and was destroyed during the war, in 1944.

Architect Bernardazzi often performed orders of Petrokokino and in addition to the department store he built three tenement houses for them: at Marazlievskaya 12 and Troitskaya 18 and 20. 
Largely differing from each other architecturally, all three buildings were based, like the Levita house, on Bernardazzi's "plan project".
Each has surprising and recognizable elements.  

The house on Marazlievskaya 12 is strictly symmetrical and has semicircular bay windows on the extreme axes at the level of the second floor, which serve as the base for the openwork loggias of the third floor. Inside the house there is a spacious perimeter oval staircase.

The house at 18, Trinity Street is made in a mixture of Renaissance, Neo-Romanesque style, and Bessarabian-Romanian stylizations. In addition to the traditional stucco decorations, colorful tiles are used on the facade.

The neighboring house, at 20, Troitska Street, is not so original in architectural style, but is more ornate. The facade is in neo-baroque style and the arch with a coffered ceiling is painted with stencil ornaments. Inside, the elegant porch with a perimeter staircase and rounded corners has been preserved.`,
    textUkrainian: `Брати Петрококіно - процвітаюча грецька купецька сім'я, яка володіла одними з найбільших статків серед греків Одеси і заснувала перший у місті універсальний магазин. 

Будівля універмагу, "магазину, де є все", розташовувалася на Грецькій у парному кварталі між Рішельєвською та Катерининською, і її зруйнували під час війни, 1944 року.

Архітектор Бернардацці часто виконував замовлення Петрококіно і, крім універмагу, побудував для них три прибуткові будинки: на Маразліївській, 12, Троїцькій, 18 і 20. 
Значною мірою відрізняючись одна від одної архітектурно, всі три будівлі ґрунтувалися, як і будинок Левіта, на "плановому проєкті" Бернардацці.
Кожна з них має дивовижні та впізнавані елементи.  

Будинок на Маразліївській, 12 суворо симетричний і має по крайніх осях напівкруглі еркери на рівні другого поверху, які слугують основою ажурних лоджій третього поверху. Всередині будинку розташовані просторі периметральні сходи овальної форми.

Будинок на Троїцькій, 18 виконаний у змішанні ренесансу, неороманського стилю і бессарабсько-румунських стилізацій. Крім традиційного ліпного декору на фасаді використано строкату кахельну плитку.

Сусідній будинок, на Троїцькій, 20, не настільки самобутній стилем архітектури, але оформлений пишніше. Фасад витриманий у стилістиці необароко, а арка з кесонованою стелею розписана трафаретними орнаментами. Усередині зберігся елегантний під'їзд із периметральними сходами та заокругленими кутами.`,
    textDeutsch: `Gebrüder Petrokokino stammten aus einer reichen griechischen kaufmännischen Familie, die eines der größten Vermögen unter den Griechen in Odesa besaß und das erste Kaufhaus in der Stadt gründete. Das Kaufhaus - „das Geschäft, in dem es alle gibt“ - lag in der Griechischen Straße, im Quartal mit geraden Hausnummern zwischen der Richelieu-Straße und Katharinen-Straße und wurde in der Kriegszeit 1944 zerstört.

    Der Architekt Bernardazzi arbeitete oft im Auftrag der Familie Petrokokino und baute für sie neben dem Warenhaus noch drei Miethäuser: in der Marazliievska-Straße 12 und Troitska-Str. 18 und 20. Ungeachtet der architektonischen Unterschiede zwischen diesen Häusern, liegt ihnen - wie auch dem Haus von Levit - das Planungsprojekt zu Grunde. Dabei hat jedes Haus einmalige und erkennbare Elemente.

Das Haus in der Marazliievska-Str. 12 ist streng symmetrisch und hat an den Außenachsen der zweiten Etage halbrunde Erker, die eine Grundlage für durchbrochene Loggias in der dritten Etage bilden. Innerhalb des Hauses befindet sich ein geräumiges ovales Treppenhaus.   

Das Haus ist eine Stilmischung von Renaissance, Neuromanik und der bessarabien-rumänischen Stilistik. Neben der traditionellen Stuckdekoration werden an der Fassade auch bunte Kachelplatten verwendet. 

Das Nachbarhaus in der Troiitska-Str. 20 hat keine so eigenartige Architektur, ist jedoch prachtvoller gebaut. Die Fassade ist im Neobarockstil gestaltet. Der Torbogen mit einer schönen Decke ist mit Ornamenten bemalt. Im Inneren blieb ein elegantes Treppenhaus mit gerundeten Ecken erhalten. 
`,
  },
  {
    id: 'Bernardazzi',
    author: 'Бернардацци Александр Иосифович',
    authorUkrainian: 'Бернардаццi Oлександр Йоcипович',
    authorEnglish: 'Bernardazzi Alexander',
    authorDeutsch: 'Bernardazzi Alexandr',
    projectName: 'Водолечебница Абеля и Валика',
    projectNameUkrainian: 'Водолікарня Абеля і Валіка',
    projectNameEnglish: 'Abel and Walik Water Spa',
    projectNameDeutsch: 'Wasserheilanstalt von Abel und Valik',
    photos: [Hydrotherapy_Valik1, Hydrotherapy_Valik2],
    text: `Бум гидропатической медицины пришелся в Одессе на два последних десятилетия XIX в. В городе массово основывались и строились десятки частных водолечебниц различной степени представительности: Воллернера, Имбера, Рабиновича и Ясиновского, Исаковича и многих других. 

Видное место среди одесских врачей-гидропатов занимал знаменитый провизор Валик, который основал свою водолечебницу совместно с доктором Абелем. 
В 1902 г. на углу Елисаветинской и Преображенской было окончено строительство роскошного мавританского здания водолечебницы Абеля и Валика, проект которой был выполнен инженером Ландесманом и значительно переработан Бернардацци.

В одном из номеров Одесского листка за 1900 год можно встретить упоминание о закладке здания:
«На днях состоялась закладка нового гидропатического заведения д-ра В. И. Абеля, сооружаемого на углу Преображенской и Елисаветинской ул. Новое здание будет состоять из 2 ½ этажей. Первый этаж будет отведен под гидропатическое заведение, а во втором будет помещаться лечебница для нервных больных с постоянными кроватями. Сооружаемая водолечебница будет состоять из 2-х классов, по два зала в каждом — для мужчин и женщин. Второй класс рассчитан на удовлетворение потребностей среднего класса, плата за лечение не будет превышать 12-ти руб. в месяц. На крыше всего здания будет устроен обширный сад для больных. Всё сооружение обойдется до 150 тыс. рублей и будет готово не позже октября текущего года».
`,
    textEnglish: `The boom of hydropathic medicine took place in Odessa in the last two decades of the 19th century. Dozens of private hydropathic clinics of varying degrees of representation were massively founded and built in the city: Wallerner, Immer, Rabinovich and Yasinovsky, Isakovich and many others. 

A prominent place among Odessa hydropathic doctors was occupied by the famous Provisor Valik, who founded his hydropathic clinic together with Dr. Abel. 
In 1902, at the corner of Yelisavetinskaya and Preobrazhenskaya, the construction of a luxurious Moorish building of Abel and Valik's hydropathic clinic was completed, whose project was made by engineer Landesman and greatly revised by Bernardazzi.

In one of the issues of the Odessa Gazette for 1900 it is possible to meet a mention of the laying of the building:
"The laying of the new hydropathic establishment of Dr. W. I. Abel, being erected at the corner of Preobrazhenskaya and Elisavetinskaya Streets, took place the other day. The new building will consist of 2 ½ stories. The first floor will be occupied by a hydropathic institution, while the second will house a hospital for the nervous system with permanent beds. The hydropathic hospital to be erected will consist of two classes, two halls in each, for men and women. The second class is designed to meet the needs of the middle class, the treatment fee will not exceed 12 rubles per month. On the roof of the entire building will be arranged extensive garden for the sick. The entire structure will cost up to 150 thousand rubles and will be ready no later than October this year.`,
    textUkrainian: `Бум гідропатичної медицини припав в Одесі на два останні десятиліття XIX ст. У місті масово засновували і будували десятки приватних водолікарень різного ступеня показності: Воллернера, Імбера, Рабиновича і Ясиновського, Ісаковича і багатьох інших. 

Чільне місце серед одеських лікарів-гідропатів посідав знаменитий провізор Валик, який заснував свою водолікарню спільно з доктором Абелем. 
У 1902 р. на розі Єлисаветинської та Преображенської було закінчено будівництво розкішної мавританської будівлі водолікарні Абеля і Валіка, проєкт якої був виконаний інженером Ландесманом і значно перероблений Бернардацці.

В одному з номерів Одеського листка за 1900 рік можна зустріти згадку про закладення будівлі:
"Днями відбулася закладка нового гідропатичного закладу д-ра В. І. Абеля, споруджуваного на розі Преображенської та Єлисаветинської вул. Нова будівля складатиметься з 2 ½ поверхів. Перший поверх буде відведено під гідропатичний заклад, а в другому міститиметься лікарня для нервових хворих із постійними ліжками. Споруджувана водолікарня складатиметься з 2-х класів, по два зали в кожному - для чоловіків і жінок. Другий клас розрахований на задоволення потреб середнього класу, плата за лікування не перевищуватиме 12-ти руб. на місяць. На даху всієї будівлі буде влаштовано великий сад для хворих. Вся споруда обійдеться до 150 тис. рублів і буде готова не пізніше жовтня поточного року`,
    textDeutsch: `Der Boom der hydropathischen Medizin entfällt in Odesa auf zwei letzte Jahrzehnte des 20. Jahrhunderts. In der Stadt wurden Dutzende von privaten Wasserheilanstalten mit unterschiedlichster Repräsentanz errichtet: die Wasserheilanstalten von Vollerner, Imber, Rabinovych, Yasinovskyi und vielen anderen.  

Eine bemerkenswerte Stellung unter den Ärzten-Hydropathen hatte in Odesa der bekannte Provisor Valik, der seine Wasserheilanstalt in Kooperation mit Doktor Abel gründete. An der Ecke der Jelisavetinska- und Preobrazhenska-Straße wurde 1902 die prachtvolle Wasserheilanstalt von Abel und Valik im mauretanischen Baustil fertig gebaut. Das Gebäude wurde von Ingenieur Landesmann entworfen und von Bernardazzi wesentlich überarbeitet.   

In einem Nachrichtenblatt Odesas von 1900 wurde die Grundsteinlegung so beschrieben: „In diesen Tagen fand die Grundsteinlegung der neuen hydropathischen Anstalt von Dr. Abel statt, die an der Kreuzung der Jelisavetinska- und Preobrazhenska-Straße errichtet wird. Das neue Gebäude wird 2,5 Etagen haben. Im Erdgeschoss wird sich die hydropathische Anstalt selbst befinden. Im ersten Obergeschoss wird die Klinik mit Betten für Kranke mit Nervenkrankheiten untergebracht. Die Wasserheilanstalt wird für zwei Klassen mit jeweils zwei Sälen für Männer und Frauen gebaut. Die zweite Klasse richtet sich auf die Bedürfnisse des Mittelstands. Die Behandlungskosten sollen 12 Tausend Rubel im Monat nicht übersteigen. Auf dem Dach des Gebäudes wird ein großer Garten für Kranke eingerichtet. Die Baukosten belaufen sich auf 150 Tausend Rubel. Das Gebäude soll spätestens im Oktober des laufenden Jahres fertig gebaut werden.“ 
`,
  },
  {
    id: 'Bernardazzi',
    author: 'Бернардацци Александр Иосифович',
    authorUkrainian: 'Бернардаццi Oлександр Йоcипович',
    authorEnglish: 'Bernardazzi Alexander',
    authorDeutsch: 'Bernardazzi Alexandr',
    projectName: 'Доходный дом Гринчака',
    projectNameUkrainian: 'Прибутковий будинок Грінчака',
    projectNameEnglish: 'Profitable house of Grinchak',
    projectNameDeutsch: 'Das Haus von Hrynchak ',
    photos: [Grinchenko1, Grinchenko2],
    text: `Доходный дом Гринчака был возведен в 1899 г. на углу Маразлиевской и Базарной. Его архитектура сочетает ренессанс и необарокко. 
Квартиры в доме предназначались для состоятельных съемщиков, что подчеркивалось не только пышными фасадами, но и довольно богатыми интерьерами.

На момент возведения здание являлось самым большим многоквартирным домом Маразлиевской и одним из самых крупногабаритных в районе своего расположения.
В доме сохранились просторные подъезды с мраморными лестницами, геометрические витражи, потолочная лепка и ряд других важных артефактов старины.

Дом знаменит тем, что в его угловом помещении расположен Всемирный Клуб Одесситов им. Жванецкого.`,
    textEnglish: `The Grinchak house was erected in 1899 on the corner of Marazlievskaya and Bazarnaya. Its architecture combines Renaissance and neo-Baroque. 
Apartments in the house were meant for wealthy tenants, which was emphasized not only by the magnificent facades, but also by the rather rich interiors.

At the time of its construction, the building was the largest apartment building in Marazlievskaya and one of the largest in the area of its location.
The house retains spacious porches with marble staircases, geometric stained glass windows, ceiling molding and a number of other important artifacts of antiquity.

The house is famous for housing the Zhvanetsky World Club of Odessits in its corner room.`,
    textUkrainian: `Прибутковий будинок Гринчака було зведено 1899 року на розі Маразліївської та Базарної. Його архітектура поєднує ренесанс і необароко. 
Квартири в будинку призначалися для заможних наймачів, що підкреслювалося не тільки пишними фасадами, а й досить багатими інтер'єрами.

На момент зведення будівля була найбільшим багатоквартирним будинком на Маразліївській і одним із найбільш великогабаритних у районі свого розташування.
У будинку збереглися просторі під'їзди з мармуровими сходами, геометричні вітражі, стельове ліплення і низка інших важливих артефактів старовини.

Будинок знаменитий тим, що в його кутовому приміщенні розташований Всесвітній Клуб Одеситів ім. Жванецького.`,
    textDeutsch: `Das Miethaus von Hrynchak wurde 1899 an der Kreuzung der Straßen Marazliievska und Bazarba erbaut. Seine Architektur verbindet die Elemente von Renaissance und Neubarock.
    
    Die Mietwohngen wurden für wohlhabende Mieter vorgesehen, was nicht nur durch prachtvolle Fassaden, sondern auch durch recht reiche Inneneinrichtung hervorgehoben wurde.   
Zur Zeit der Einrichtung war das Gebäude das größte Mehrfamilienhaus in der Marazliievska-Straße und eines der größten in diesem Stadtbezirk. 

Im Haus blieben geräumige Treppenhäuser mit Marmortreppen, geometrische Glasmalereien, Deckenstuck und weitere alte Elemente erhalten. Das Haus ist dadurch bekannt, dass in seinem Eckraum der internationale Zhvanetskyi-Club der Odessiten seinen Sitz hat. 
`,
  },
  {
    id: 'Bernardazzi',
    author: 'Бернардацци Александр Иосифович',
    authorUkrainian: 'Бернардаццi Oлександр Йоcипович',
    authorEnglish: 'Bernardazzi Alexander',
    authorDeutsch: 'Bernardazzi Alexandr',
    projectName: 'Дом Доппельмейера',
    projectNameUkrainian: 'Будинок Доппельмейера',
    projectNameEnglish: 'Doppelmeyer House',
    projectNameDeutsch: 'Das Haus von Doppelmeyer',
    photos: [Doppelmeistr1, Doppelmeistr2],
    text: `Дом Доппельмейера — один из наиболее характерных образцов романтической эклектики к формам которой Бернардацци часто обращался, свободно и виртуозно смешивая самые неожиданные в своем сочетании стили.

Дом был построен в 1891-92 гг. на одной из самых престижных одесских улиц — Херсонской (нын. Пастера). Его архитектура — удивительное сочетание византийского, псевдорусского и мавританского стилей. 

Ранее дом имел три этажа, однако в 1950-е гг. был надстроен четвертым, что заметно изменило его облик. Также дом может похвалиться самыми красивыми сохранившимися воротами улицы, стилизованными под морскую астролябию`,
    textEnglish: `Doppelmeyer House is one of the most typical examples of romantic eclecticism to which Bernardazzi often turned, freely and masterly mixing the most unexpected in its combination of styles.

The house was built in 1891-92 on Kherson (now Pasteur) Street, one of the most prestigious streets in Odessa. Its architecture is an amazing combination of Byzantine, Pseudo-Russian and Moorish styles. 

Earlier the house had three stories, but in 1950s it was added by the fourth story and that significantly changed its look. The house also boasts the most beautiful surviving gates of the street, stylized as a nautical astrolabe`,
    textUkrainian: `Будинок Доппельмейєра - один з найхарактерніших зразків романтичної еклектики, до форм якої Бернардацці часто звертався, вільно і віртуозно змішуючи найнесподіваніші у своєму поєднанні стилі.

Будинок був побудований у 1891-92 рр. на одній з найпрестижніших одеських вулиць - Херсонській (нині. Пастера). Його архітектура - дивовижне поєднання візантійського, псевдоруського та мавританського стилів. 

Раніше будинок мав три поверхи, проте в 1950-ті рр. був надбудований четвертим, що помітно змінило його вигляд. Також будинок може похвалитися найкрасивішими збереженими воротами вулиці, стилізованими під морську астролябію.`,
    textDeutsch: `Das Haus von Doppelmeyer ist eines der charakteristischen Muster des romantischen Eklektizismus, auf dessen Formen Bernardazzi oft zurückgriff, indem er ganz unerwartet verschiedene Baustile frei und meisterhaft vermischte.      

Das Haus wurde 1891-1892 in einer der nobelsten Straßen von Odesa – Cherson-Straße (heute Paster-Straße) - gebaut. Seine Architektur stellt eine einmalige Mischung von byzantinischem, pseudorussischem und mauretanischem Stil dar.
    
Das Haus hatte früher drei Stockwerke, wurde aber in den 1950-er Jahren aufgestockt, was seine Ansicht bemerkbar veränderte. Das Haus kann auf das schönste Tor in der ganzen Straße stolz sein, das als Meeresastrolabium stilisiert ist.    
`,
  },

  {
    id: 'Bernardazzi',
    author: 'Бернардацци Александр Иосифович',
    authorUkrainian: 'Бернардаццi Oлександр Йоcипович',
    authorEnglish: 'Bernardazzi Alexander',
    authorDeutsch: 'Bernardazzi Alexandr',
    projectName: 'Доходный дом Юровского',
    projectNameUkrainian: 'Прибутковий будинок Юровського',
    projectNameEnglish: 'Profitable house of Yurovsky',
    projectNameDeutsch: 'Das Miethaus von Yurovskyi',
    photos: [Mavrokordato1, Mavrokordato2],
    text: `Огромный высокоэтажный, по меркам своей эпохи, дом на Греческой, 50 строился в 1897 г. по заказу купца Н. Юровского. 

В одном из номеров журнала Строитель за тот же 1897 г. в качестве архитектора дома указывался С. А. Ландесман. 

Однако участие в проекте А. О. Бернардацци более чем очевидно, так как в архитектуре дома прослеживается огромное число присущих именно ему элементов и решений, а некоторые из них и вовсе копируют детали из более ранних проектов зодчего. Например, декоративная аркатура третьего этажа практически идентична таковой на фасаде дома Петрококино на Троицкой, 20.

Застройка участка состоит из лицевого дома в четыре этажа и внутридворового флигеля — в три. Оба здания содержали квартиры высокого уровня комфорта и оснащения, предназначавшиеся для состоятельных слоев населения. 

Лицевой фасад выразителен и очень представительно оформлен, архитектура сочетает ренессанс и барокко. В доме было использовано несколько передовых технических решений, например, лифт и паровое отопление.
`,
    textEnglish: `A huge high-rise, by the standards of its era, house on Grecheskaya, 50 was built in 1897 by order of the merchant N. Yurovsky. 

In one of the issues of the Stroitel magazine for the same year 1897, S. Landesman was listed as the architect of the house. 

However, the involvement of A. O. Bernardazzi in the project is more than obvious, as in the architecture of the house can be traced a huge number of intrinsic elements and solutions, and some of them even replicate parts from earlier projects of the architect. For instance the decorative arcature of the third floor is almost identical to the facade of the Petrokokino house at Troitskaya, 20.

The development of the site consists of a four-story front house and a three-story backyard outbuilding. Both buildings contained apartments of high comfort and equipment, intended for the wealthy people. 

The facade is expressive and very presentable, the architecture combines Renaissance and Baroque. Several cutting-edge technical solutions were used in the house, such as the elevator and the steam heating.`,
    textUkrainian: `Величезний високоповерховий, за мірками своєї епохи, будинок на Грецькій, 50 будували 1897 року на замовлення купця М. Юровського. 

В одному з номерів журналу "Будівельник" за той самий 1897 р. як архітектора будинку вказували С. А. Ландесмана. 

Однак участь у проєкті О. О. Бернардацці більш ніж очевидна, оскільки в архітектурі будинку простежується величезна кількість властивих саме йому елементів і рішень, а деякі з них і зовсім копіюють деталі з більш ранніх проєктів зодчого. Наприклад, декоративна аркатура третього поверху практично ідентична такій на фасаді будинку Петрококіно на Троїцькій, 20.

Забудова ділянки складається з лицьового будинку в чотири поверхи і внутрішньодворового флігеля - у три. Обидві будівлі містили квартири високого рівня комфорту й оснащення, що призначалися для заможних верств населення. 

Лицьовий фасад виразний і дуже представницький, архітектура поєднує ренесанс і бароко. У будинку було використано кілька передових технічних рішень, наприклад, ліфт і парове опалення.`,
    textDeutsch: `Das riesengroße mehrstöckige Gebäude, die in der Griechischen Straße 50 liegt, wurde 1897 im Auftrag des Kaufmanns Yurovskyi gebaut.
    
In einer Erscheinung der Zeitschrift „Baufachmann“ von 1987 wurde als Architekt Landesmann genannt.
    
Die Mitwirkung von Bernardazzi an diesem Bauprojekt ist aber mehr als offensichtlich: in der Gebäudearchitektur sind zahlreiche Elemente und Lösungen zu beobachten, die für Bernardazzi charakteristisch waren.  Einige davon stellen sogar Kopien aus früheren Projekten von Bernardazzi dar. So zum Beispiel ist dekorative Arkatur im dritten Stock fast identisch der Arkatur an der Fassade des Petrokokino-Hauses in der Troitska-Straße 20.

Das Grundstück wurde mit einem vierstöckigen Hauptgebäude und einem dreistöckigen Hofflügel bebaut. In beiden Gebäuden wurden hochkomfortable Wohnungen für wohlhabende Mieter eingerichtet.

Die Hauptfassade wurde sehr ausdrucksvoll und repräsentativvoll gestaltet. Seine Architektur verbindet die Elemente von Renaissance und Barock. Im Haus wurden fortschrittliche technische Lösungen realisiert: der Aufzug und die Dampfheizung. 
`,
  },
  {
    id: 'Bernardazzi',
    author: 'Бернардацци Александр Иосифович',
    authorUkrainian: 'Бернардаццi Oлександр Йоcипович',
    authorEnglish: 'Bernardazzi Alexander',
    authorDeutsch: 'Bernardazzi Alexandr',
    projectName: 'Портал лавки Асвадуровых',
    projectNameUkrainian: 'Портал крамниці Асвадурових',
    projectNameEnglish: 'Aswadurov"s shop portal',
    projectNameDeutsch: 'Das Ladenportal von Asvadurovs',
    photos: [Asvadurov1],
    text: `Асвадуровы — знаменитая купеческая семья армянского происхождения, наиболее успешные и известные табачные фабриканты дореволюционной Одессы (наровне с Поповыми и Дурьянами).

В Одессе им принадлежала табачная фабрика на Успенской и два больших доходных дома. 

С середины 1890-х, магазин Асвадуровых обосновался в легендарном доме Вагнера и со стороны Екатерининской вход в него обозначался роскошным мраморным порталом, выполненным в 1896 г. по эскизам Бернардацци в мастерской Менционе.

В оформлении портала использовался не только итальянский мрамор лучших сортов, но и редкий розовый гранит, а также стекла со светотравлением.
`,
    textEnglish: `The Asvadurovs were a famous merchant family of Armenian origin, the most successful and famous tobacco manufacturers of pre-revolutionary Odessa (on a par with the Popovs and the Duryans).

In Odessa, they owned a tobacco factory on Uspenskaya and two large profitable houses. 

From the middle of the 1890s the Asvadurovs' store was located in the legendary Wagner's house and from the side of Ekaterininskaya the entrance was marked by a luxurious marble portal, made in 1896 by Bernardazzi in the Menzione workshop.

The portal was decorated not only with Italian marble of the best varieties, but also with rare pink granite and light-etching glass.`,
    textUkrainian: `Асвадурови - знаменита купецька сім'я вірменського походження, найуспішніші та найвідоміші тютюнові фабриканти дореволюційної Одеси (нарівні з Поповими і Дур'янами).

В Одесі їм належала тютюнова фабрика на Успенській і два великих прибуткових будинки. 

З середини 1890-х магазин Асвадурових влаштувався в легендарному будинку Вагнера, і з боку Катерининської вхід до нього позначався розкішним мармуровим порталом, виконаним 1896 року за ескізами Бернардацці в майстерні Менціоне.

В оформленні порталу використовували не тільки італійський мармур найкращих сортів, а й рідкісний рожевий граніт, а також скло зі світлотравленням.`,
    textDeutsch: `Asvadurovs ist eine bekannte kaufmännische Familie der armenischen Abstammung und die erfolgreichsten Zigarettenhersteller in der Odesa vor der Revolution. (Sie stehen in einer Reihe mit Popovs und Durians).
    
    In Odesa gehörte ihnen die Zigarettenfabrik in der Uspenska-Straße und zwei große Miethäuser. 
Ab Mitte der 1890-er Jahren befand sich das Geschäft von Asvadurovs im legendären Wagner-Haus.

Vonseiten der Katharinen-Straße wurde der Eingang ins Geschäft mit einem prachtvollen Marmorportal gekennzeichnet, das 1896 nach den Zeichnungen von Bernardazzi in der Werkstatt von Mencione gefertigt wurde.

Bei der Gestaltung des Eingangsportals wurde nicht nur der beste italienische Marmor verwendet, sondern auch seltener Rosagranit sowie die Fenster mit Wiederspiegelung.    
`,
  },
  {
    id: 'Bernardazzi',
    author: 'Бернардацци Александр Иосифович',
    authorUkrainian: 'Бернардаццi Oлександр Йоcипович',
    authorEnglish: 'Bernardazzi Alexander',
    authorDeutsch: 'Bernardazzi Alexandr',
    projectName: 'Здание Новой биржи',
    projectNameUkrainian: 'Будівля Нової біржі',
    projectNameEnglish: 'The New Exchange building',
    projectNameDeutsch: 'Die Neue Börse ',
    photos: [
      New_exchange1,
      New_exchange2,
      New_exchange3,
      New_exchange4,
      New_exchange5,
      New_exchange6,
    ],
    text: `К концу XIX в. здание Старой Биржи, построенной в 1820-30-е гг. на Приморском  Бульваре, перестало удовлетворять потребности одесской купеческой общины. 
За несколько десятилетий строение устарело по технической части, стало тесным и мало пригодным для регулярных масштабных торгов. В итоге здание передали Городской Думе и не говори в 1891 году объявили международный конкурс на составление проекта строительства нового биржевого здания.  

Уже в следующем году изй тридцати претендентом выбрали тройку победителей: проекты В. И.  , Г. А. Ляндау-Гугентегера и Ю. М. Дмитренко. Архитектор      взял главный приз, но амбиции движущей части одесского общества не были полностью удовлетворены.

В 1894 году к реализации был принят проект-победитель, но в переработке Александра Осиповича Бернардацци, который и считается главным архитектором здания Новой биржи на пересечении бывших Итальянской и Полицейской улиц.
 
Возведенный в стиле флорентийского Возрождения и венецианской готики одесский храм торговли в своей роскоши соперничал с   частными особняками знати и  наряду с коммерческой функцией (торговые площадки, купеческая управа) на его зал была возложена представительская функция по приему светских балов, проведению концертов заезжих знаменитостей и собраний местных литераторов и ученых.

Уровень убранства здания впечатлял, а в самой Бирже было создано несколько ключевых масштабных локаций, и сегодня являющихся ее визитной карточкой:

— огромная, перекрытая бетонным куполом лоджия парадной лестницы со стороны Полицейской, внутри которой Бернардацци оригинальным образом поместил две  недели назад ростральные колонны;

— двор Хлебной Биржи, известный также как Итальянский дворик, к архитектуре которого приложил руку еще один выдающийся одесский архитектор — Владислав Домбровский;

— главный зал с роскошным кессонированным потолком, витражами и высокой ложей с фонарями. В оформлении принимали участие скульпторы М. Л. Молинари, Л. Д. Менционе, Б. В. Эдуардс. Зал расписан под обои петербургским художником Н. Н. Каразиным и оформлен панно флорентийского художника Кассиоли;

— большой вестибюль, расположенный в цокольном этаже под главным залом, который использовался не только как альтернативный лоджии доступ публики в зал, но и как малый зал для торгов.

В следующем же 1900 году к огромному количеству уникальных архитектурных деталей здания добавился мраморный бюст А. О. Бернардацци, созданный скульптором Борисом Васильевичем Эдуардсом и установленный в лоджии около центрального входа в ознаменование пятидесятилетнего юбилея творческой деятельности архитектора.

С 1937 года в здании Биржи расположена Одесская областная Филармония.
`,
    textEnglish: `By the end of the 19th century the building of the Old Stock Exchange, built in the 1820s-30s on Primorsky Boulevard, ceased to meet the needs of the Odessa merchant community. 
Over several decades, the building had become technically obsolete, cramped and of little use for regular large-scale trading. As a result the building was given to the city Duma, and in 1891 it was announced an international contest for designing a new stock exchange building.  

The very next year, out of thirty applicants, three were chosen: designs by V. I.  G. A. Lyandau-Gugenteger and Y. M. Dmitrenko. The architect took the main prize, but the ambitions of the moving part of the Odessa society were not completely satisfied.

In 1894 the winning project was accepted for realization, but it was redesigned by Alexander Osipovich Bernardazzi, who is considered to be the main architect of the New Exchange building at the crossing of the former Italianskaya and Politsinskaya Streets.
 
Built in the style of Florentine Renaissance and Venetian Gothic, the temple of trade in Odessa in its luxury rivaled the private mansions of the nobility. Along with its commercial function (market places, merchant's board) its hall was assigned the representative function of receiving society balls, holding concerts of visiting celebrities and meetings of local writers and scientists.

The level of decoration of the building was impressive, and several key large-scale locations were created in the Exchange itself, which are still its hallmark today:

- The enormous, concrete-domed loggia of the front staircase on the Police side, inside of which Bernardazzi had originally placed rostral columns two weeks earlier;

Translated with www.DeepL.com/Translator (free version)`,
    textUkrainian: `До кінця XIX ст. будівля Старої Біржі, побудованої в 1820-30-ті рр. на Приморському Бульварі, перестала задовольняти потреби одеської купецької громади. 
За кілька десятиліть будова застаріла з технічної частини, стала тісною і мало придатною для регулярних масштабних торгів. Зрештою будівлю передали Міській Думі і не кажучи вже про те, що 1891 року оголосили міжнародний конкурс на складання проєкту будівництва нової біржової будівлі.  

Уже наступного року з тридцяти претендентів обрали трійку переможців: проекти В. І. Лянда, Г. А. Лянда.  Г. А. Ляндау-Гугентегера та Ю. М. Дмитренка. Архітектор узяв головний приз, але амбіції рушійної частини одеського суспільства не були повністю задоволені.

1894 року до реалізації було прийнято проєкт-переможець, але в переробці Олександра Йосиповича Бернардацці, який і вважається головним архітектором будівлі Нової біржі на перетині колишніх Італійської та Поліцейської вулиць.
 
Зведений у стилі флорентійського Відродження і венеціанської готики одеський храм торгівлі у своїй розкоші змагався з приватними особняками знаті, і поряд з комерційною функцією (торговельні майданчики, купецька управа) на його зал поклали представницьку функцію з приймання світських балів, проведення концертів заїжджих знаменитостей і зібрань місцевих літераторів і вчених.

Рівень оздоблення будівлі вражав, а в самій Біржі було створено кілька ключових масштабних локацій, які і сьогодні є її візитною карткою:

- величезна, перекрита бетонним куполом лоджія парадних сходів з боку Поліцейських, усередині якої Бернардацці оригінальним чином помістив два тижні тому ростральні колони;

- двір Хлібної Біржі, відомий також як Італійський дворик, до архітектури якого доклав руку ще один видатний одеський архітектор - Владислав Домбровський;

- головна зала з розкішною кесонованою стелею, вітражами і високою ложею з ліхтарями. В оформленні брали участь скульптори М. Л. Молінарі, Л. Д. Менціоне, Б. В. Едуардс. Зал розписаний під шпалери петербурзьким художником Н. Н. Каразіним і оформлений панно флорентійського художника Кассіолі;

- великий вестибюль, розташований у цокольному поверсі під головною залою, який використовували не тільки як альтернативний лоджії доступ публіки до зали, а й як малу залу для торгів.

Наступного ж 1900 року до величезної кількості унікальних архітектурних деталей будівлі додалося мармурове погруддя О. О. Бернардацці, створене скульптором Борисом Васильовичем Едуардсом і встановлене в лоджії біля центрального входу в ознаменування п'ятдесятирічного ювілею творчої діяльності архітектора.

З 1937 року в будівлі Біржі розташована Одеська обласна Філармонія.`,
    textDeutsch: `Gegen Ende des 20. Jahrhunderts konnte das Gebäude der Alten Börse, die 1820-1830 auf dem Primorkij Boulevard errichtet wurde, die Bedürfnisse der kaufmännischen Gemeinschaft von Odesa nicht mehr befriedigen. Im Laufe von mehreren Jahrzehnten veralteten sich technische Anlagen des Gebäudes, es wurde eng und für den regelmäßigen und groß angelegten Handel in der Hafenstadt wenig geeignet. Im Ergebnis wurde das Gebäude an die Stadtduma überreicht. 1981 wurde ein internationaler Wettbewerb für die Planung eines neuen Börsengebäudes ausgerufen. Schon im nächsten Jahr wurden aus 30 eingereichten Wettbewerbsarbeiten die drei besten ausgewählt, und zwar die Projekte von V. Prokhasko, G. Landau-Gugenteger und J. Dmytrenko. Der Architekt Dmytrenko gewann den Hauptpreis, konnte aber den Ansprüchen der aktiven städtischen Gemeinschaft in vollem Maße nicht gerecht werden. Im Jahre 1894 wurde jedoch seine Planung für die Umsetzung empfohlen. Aber davor wurde sie von Alexander Bernardazzi überarbeitet, der nun als Hauptarchitekt der Neuen Börse gilt, die an der Kreuzung der ehemaligen Italienischen Straße und der Polizeistraße liegt. 

Errichtet im Renaissancestil von Florenz und der venezianischen Gotik eiferte der Handelstempel von Odesa mit den schönsten privaten Villas des Adels. Neben seiner Handelsfunktion (Handelsplätze, kaufmännische Verwaltung) wurde der Börsensaal auch für repräsentative Zwecke genutzt und diente zur Durchführung von Bällen, Konzerten der tourenden Prominenz und Versammlungen lokaler Autoren und Wissenschaftler. Die Verzierung des Gebäudes war sehr beeindruckend. In der Börse selbst wurden wichtige Lokationen gebildet, die auch heute noch als Visitenkarte der Stadt gelten:     

    -	die riesengroße, mit einer Betonkuppel gedeckte Loggia an der Paradentreppe vonseiten der Polizeistraße. In dieser Loggia befinden sich zwei rostrale Säulen, die Bernardazzi sehr eigenartig einbaute;

    -	der Innenhof der Brotbörse, bekannt auch als Italienischer Hof, an dessen Architektur ein weiterer herausragender Architekt von Odesa, Vladyslav Dombrovskyi mitwirkte;

    -	der Hauptsaal mit einer prunkvollen Decke, den Glasfensterbildern und einer hohen Loggia mit Laternen. An der Gestaltung des Börsensaals waren die Bildhauer M. Molinari, L. Mentsione und B. Eduards beteiligt. Die Wände wurden von dem Maler N. Karazin aus St. Petersburg in Form von Tapeten bemalt und mit dem Wandbild des florentinischen Malers Caccioli geschmückt;

    -	die große Vorhalle im Gebäudesockel unter dem Hauptsaal, die nicht nur als Alternative für eine Loggia diente, um den Zugang des Publikums zum Hauptsaal zu erleichtern, sondern auch als Kleiner Handelssaal genutzt wurde.      

    Im nächsten Jahr (1900) gesellte sich zu vielen einmaligen architektonischen Details die Marmorbüste von A. Bernardazzi, der von dem Bildhauer Borys Eduards geschaffen und anlässlich des 50-jährigen Jubiläums der Tätigkeit des Architekten in der Loggia am Zentraleingang aufgestellt wurde.  

Seit 1937 beherbergt die Börsengebäude die Philharmonie von Odesa. 
`,
  },
  {
    id: 'Bernardazzi',
    author: 'Бернардацци Александр Иосифович',
    authorUkrainian: 'Бернардаццi Oлександр Йоcипович',
    authorEnglish: 'Bernardazzi Alexander',
    authorDeutsch: 'Bernardazzi Alexandr',
    projectName: 'Здание Общества Взаимного кредита',
    projectNameUkrainian: 'Будівля Товариства Взаємного кредиту',
    projectNameEnglish: 'Mutual Credit Society building',
    projectNameDeutsch: 'Die Gesellschaft für gegenseitige Kredithilfe ',
    photos: [
      Mutual_Credit_Society1,
      Mutual_Credit_Society2,
      Mutual_Credit_Society3,
      Mutual_Credit_Society4,
    ],
    text: `Монументальное здание Общества Взаимного кредита возведено архитектором Бернардацци в 1903 г. под влиянием традиционных форм флорентийского Возрождения. Это одно из немногих банковских зданий в творчестве зодчего.

Архитектурный образ ОВК оказал огромное влияние на формирование ансамбля перекрестка Пушкинской и Греческой. В 1905 г. архитектор Ю. М. Дмитренко соорудил на противоположной стороне Греческой здание Учетного банка, с которым творение Бернардацци составило в своем роде комплекс — своеобразные пропилеи въезда в деловую часть Одессы по Греческой.

Здание решено трехобъемным и состоит из более высокой угловой части с малыми операционными залами и крыльями пониже, растянувшимися по Пушкинской и Греческой. 
Такое решение не свойственно для Бернардацци, предпочитавшего более простые, одно- и двухобъемные компоновки строений.

Наиболее впечатляющим из технических решений является массивный фундамент, в опоры которого были врезаны сейфы.
`,
    textEnglish: `The monumental building of the Mutual Credit Society was erected by the architect Bernardazzi in 1903 under the influence of traditional Florentine Renaissance forms. It is one of the few bank buildings in the architect's oeuvre.

The architectural image of the OVK had a great influence on the formation of the ensemble of the intersection of Pushkinskaya and Grecheskaya. In 1905 the architect YM Dmitrenko had built on the opposite side of Grecheskaya the building of bank with which Bernardazzi's creation formed a kind of complex - a kind of propylaeum of entrance to the business part of Odessa on Grecheskaya.

The building was designed with three volumes and consists of higher angular part with small operational halls and wings with lower sections, stretching along Pushkinskaya and Grecheskaya. 
Such a solution is not typical for Bernardazzi, who preferred simpler, one-and two-volume layouts of the buildings.

The most impressive of the technical solutions is the massive foundation, in the supports of which the safes were embedded.`,
    textUkrainian: `Монументальна будівля Товариства Взаємного кредиту зведена архітектором Бернардацці 1903 року під впливом традиційних форм флорентійського Відродження. Це одна з небагатьох банківських будівель у творчості зодчого.

Архітектурний образ ОВК справив величезний вплив на формування ансамблю перехрестя Пушкінської та Грецької. У 1905 р. архітектор Ю. М. Дмитренко спорудив на протилежному боці Грецької будівлю Облікового банку, з якою творіння Бернардацці склало у своєму роді комплекс - своєрідні пропілеї в'їзду в ділову частину Одеси по Грецькій.

Будівля вирішена триоб'ємною і складається з більш високої кутової частини з малими операційними залами і крилами нижче, що розтягнулися по Пушкінській і Грецькій. 
Таке рішення не властиве для Бернардацці, який віддавав перевагу простішим, одно- і двооб'ємним компонуванням будівель.

Найбільш вражаючим з технічних рішень є масивний фундамент, в опори якого були врізані сейфи.`,
    textDeutsch: `Das imposante Gebäude der Gesellschaft für gegenseitige Kredithilfe wurde von dem Architekten Bernardazzi im Jahre 1903 unter Einfluss traditioneller Formen der Florenzrenaissance errichtet. 

Es ist eines der wenigen Bankgebäude im Portfolio des Architekten. 

Die Architektur dieses Gebäudes hatte einen großen Einfluss auf die Gestaltung des Gebäudeensembles an der Kreuzung der Puschkin-Straße und der Griechischen Straße. 1905 baute der Architekt Dmytrenko auf der gegenüberliegenden Seite der Griechischen Straße das Gebäude der Erfassungsbank, das mit dem Bauwerk von Bernardazzi ein Ensemble darstellt. Die beiden Gebäude dienen als eigenartige Propyläen für die Einfahrt in den Geschäftsstadtteil Odesas. 

Das Gebäude ist dreidimensioniert und besteht aus einem höheren Eckteil mit kleinen Operationssälen und den niedrigeren Flügeln, die sich über die Puschkin-Straße und die Griechische Straße erstrecken. Diese Lösung ist für Bernardazzi nicht typisch, weil er einfachere ein- und zweidimensionale Baukonzepte bevorzug. Als technische Lösung beeindruckt das massive Fundament, in dessen Stützen Tresors eingebaut wurden.  
`,
  },
  {
    id: 'Bernardazzi',
    author: 'Бернардацци Александр Иосифович',
    authorUkrainian: 'Бернардаццi Oлександр Йоcипович',
    authorEnglish: 'Bernardazzi Alexander',
    authorDeutsch: 'Bernardazzi Alexandr',
    projectName: 'Реформатская церковь',
    projectNameUkrainian: 'Реформатська церква',
    projectNameEnglish: 'Reformed Church',
    projectNameDeutsch: 'Die Reformierte Kirche ',
    photos: [
      Reformed_Church1,
      Reformed_Church2,
      Reformed_Church3,
      Reformed_Church4,
    ],
    text: `Наравне с несохранившимся старым железнодорожным вокзалом, Реформатская церковь на Херсонской (ныне Пастера) — образец совместного творчества Бернардацци и знаменитого петербургского архитектора В. А. Шретера. 

В случае с обоими зданиями Бернардацци получил в распоряжение премированные конкурсные проекты Шретера и, попутно их дорабатывая, руководил постройкой в Одессе.

Проект церкви уходит корнями в 1882 г., когда Шретер опубликовал в журнале Зодчий первые планы и наброски будущей Реформатской церкви для Одессы. Само же строительство велось значительно позже — в 1895-96 гг. 

Постройка возводилась на средства особого фонда, который в 1890 году учредила община по инициативе пастора Е. И. Корнмана.
Помимо Бернардацци в возведении участвовал и знаменитый инженер Х. Скведер.

Эффектный и изящный храм, решенный в формах северного Возрождения с элементами неоготики, представляет собой трехэтажное здание: на первом этаже находились машины для парового отопления, на втором — квартира пастора, а на третьем — непосредственно молитвенный зал. 

В 1900 году отделочные работы в церкви полностью завершены и сам храм освящен. 

В советское время храм был закрыт, а здание, по проекту архитектора А. Минкуса, перестроено в кукольный театр.

На рубеже 1990-2000-х гг. , при финансовой поддержке Пресвитерианской церкви Америки, церковь была восстановлена. В 2002 году в храме был установлен лучший на Юге Украины орган.

Доходный дом Юровского

Огромный высокоэтажный, по меркам своей эпохи, дом на Греческой, 50 строился в 1897 г. по заказу купца Н. Юровского. 

В одном из номеров журнала Строитель за тот же 1897 г. в качестве архитектора дома указывался С. А. Ландесман. 

Однако участие в проекте А. О. Бернардацци более чем очевидно, так как в архитектуре дома прослеживается огромное число присущих именно ему элементов и решений, а некоторые из них и вовсе копируют детали из более ранних проектов зодчего. Например, декоративная аркатура третьего этажа практически идентична таковой на фасаде дома Петрококино на Троицкой, 20.

Застройка участка состоит из лицевого дома в четыре этажа и внутридворового флигеля — в три. Оба здания содержали квартиры высокого уровня комфорта и оснащения, предназначавшиеся для состоятельных слоев населения. 

Лицевой фасад выразителен и очень представительно оформлен, архитектура сочетает ренессанс и барокко. В доме было использовано несколько передовых технических решений, например, лифт и паровое отопление.
`,
    textEnglish: `Along with the old railway station that did not survive, the Reformed Church on Khersonskaya (now Pastera) is an example of the joint work of Bernardazzi and the famous St. Petersburg architect V.A. Schroeter. 

In the case of both buildings Bernardazzi received at the disposal of Schroeter's winning designs and, along the way finalizing them, led the construction in Odessa.

The project of the church goes back to 1882, when Schreter published in the journal Zodchy the first plans and sketches of the future Reformed Church for Odessa. Construction itself came much later, in 1895-96. 

The building was funded by a special fund, established in 1890 by the congregation at the initiative of pastor E. I. Kornman.
In addition to Bernardazzi in the construction was also involved famous engineer H. Skweder.

The effective and elegant church, designed in Northern Renaissance forms with Neo-Gothic elements, is a three-story building: on the first floor there were steam heating machines, on the second - the pastor's apartment, and on the third - the prayer hall itself. 

In 1900 the finishing work in the church was completely completed and the church itself was consecrated. 

During the Soviet time the church was closed and the building was rebuilt into a puppet theater according to a design by architect A. Minkus.

At the turn of 1990-2000-ies. With funding from the Presbyterian Church of America, the church was rebuilt. In 2002 the best organ in the south of Ukraine was installed in the church.

Yurovsky House

The huge high-rise (by the standards of his era) house on Grecheskaya, 50 was built in 1897 by order of the merchant N. Yurovsky. 

In one of the issues of the magazine Stroitel for the same 1897. as the architect of the house was listed S. Landesman. 

However, the involvement of A. O. Bernardazzi in the project is more than obvious, as in the architecture of the house can be traced a huge number of intrinsic elements and solutions, and some of them even replicate parts from earlier projects of the architect. For instance the decorative arcature of the third floor is almost identical to the facade of the Petrokokino house at Troitskaya, 20.

The development of the site consists of a four-story front house and a three-story backyard outbuilding. Both buildings contained apartments of high comfort and equipment, intended for the wealthy people. 

The facade is expressive and very presentable, the architecture combines Renaissance and Baroque. Several cutting-edge technical solutions were used in the house, such as the elevator and the steam heating.`,

    textUkrainian: `Нарівні зі старим залізничним вокзалом, що не зберігся, Реформатська церква на Херсонській (нині Пастера) - зразок спільної творчості Бернардацці і знаменитого петербурзького архітектора В. А. Шретера. 

У випадку з обома будівлями Бернардацці отримав у розпорядження премійовані конкурсні проєкти Шретера і, попутно їх доопрацьовуючи, керував будівництвом в Одесі.

Проєкт церкви сягає корінням 1882 року, коли Шретер опублікував у журналі "Зодчий" перші плани і начерки майбутньої Реформатської церкви для Одеси. Саме ж будівництво велося значно пізніше - у 1895-96 рр. 

Споруда зводилася на кошти особливого фонду, який 1890 року заснувала громада з ініціативи пастора Є. І. Корнмана.
Крім Бернардацці у зведенні брав участь і знаменитий інженер Х. Скведер.

Ефектний і витончений храм, вирішений у формах північного Відродження з елементами неоготики, являє собою триповерхову будівлю: на першому поверсі знаходилися машини для парового опалення, на другому - квартира пастора, а на третьому - безпосередньо молитовний зал. 

У 1900 році оздоблювальні роботи в церкві повністю завершено і сам храм освячено. 

За радянських часів храм закрили, а будівлю, за проектом архітектора А. Мінкуса, перебудували на ляльковий театр.

На рубежі 1990-2000-х рр. за фінансової підтримки Пресвітеріанської церкви Америки, церкву було відновлено. У 2002 році в храмі було встановлено найкращий на Півдні України орган.`,
    textDeutsch: `Neben dem alten Bahnhof, der nicht erhalten blieb, steht die Reformierte Kirche in der Cherson-Straße (heute Paster-Straße) als Muster der Zusammenarbeit von Bernardazzi und dem bekannten Architekten Schroeter aus St. Petersburg. Bernardazzi erhielt die prämierten Wettbewerbsprojekte für beide Gebäude von Schroeter, entwickelte sie weiter und leitete die Bauarbeiten in Odesa. Die Planung der Kirche datiert noch im Jahre 1882, als Schroeter in der Zeitschrift „Architekt“ die ersten Pläne und Skizzen der künftigen Reformierten Kirche für Odesa veröffentlichte. Die Bauarbeiten selbst wurden viel später 1895-1896 durchgeführt.  

Der Bau wurde aus einem Sonderfond finanziert, den die Pfarrei 1890 auf Initiative des Pfarrers Kornmann anlegte. Neben Bernardazzi beteiligte sich an der Errichtung der Kirche auch der bekannte Architekt Skveder.   

Das effektvolle und zierliche Tempelhaus baut auf die Formen der nördlichen Renaissance mit Elementen der Neugotik und stellt ein dreistöckiges Gebäude dar. Im Erdgeschoß befanden sich die Maschinen für die Dampfheizung, im ersten Obergeschoss lag die Wohnung des Pfarrers und im dritten Obergeschoss der Gebetsraum.  

Im Jahre 1900 wurden die Innenarbeiten in der Kirche abgeschlossen und die Kirche wurde eingeweiht. In der Sowjetzeit wurde die Kirche geschlossen. Das Gebäude selbst wurde nach dem Projekt des Architekten Minus in ein Puppentheater umgebaut. 

In den 1990-2000-er Jahren wurde die Kirche mit finanzieller Unterstützung der amerikanischen Presbyterianischen Kirche wiederhergestellt. 2022 wurde in der Kirche die beste Orgel im Süden der Ukraine installiert.
`,
  },
  {
    id: 'Torricelli',
    author: 'Торичелли Георгий Иванович',
    authorUkrainian: 'Торічеллі Георгій Іванович',
    authorEnglish: 'Torricelli Georgiy',
    authorDeutsch: 'Torricelli Georgiy',
    projectName: 'Здание Английского клуба',
    projectNameUkrainian: 'Будівля Англійського клубу',
    projectNameEnglish: 'English Club building',
    projectNameDeutsch: 'Der Englische Club',
    photos: [English_club1, English_club2],
    text: `Изящное полутораэтажное здание Английского клуба относится к позднему творчеству Торичелли и проектировалось им в 1840-е гг. параллельно со строительством комплекса лавок Пале-Рояля по соседству. 

Фактически Английский клуб должен был стать неотъемлемой частью этого ансамбля, замыкая Театральную площадь со стороны моря. Впоследствии строительство Пале-Рояля в полной мере не было завершено и сама театральная площадь не получила парадного обрамления.

Английский клуб успели построить, но он остался отдельно стоящим зданием, вырванным из контекста замыслов Торичелли, но хорошо гармонирующим со своим историческим архитектурным окружением периода классицизма.

Стиль здания выполнен в канонах ранней эклектики и сочетает в себе, как элементы классицизма и ампира, так и явные ренессансные черты. Располагаясь на наклонном рельефе, здание имеет переменную этажность от одного этажа со стороны Театрального сада к полутора этажам со стороны Думской площади.
`,
    textEnglish: `The elegant one-and-a-half-story English Club is a late work of Torricelli and was designed by him in the 1840s in parallel with the construction of the Palais Royal shop complex next door. 

In fact, the English Club was to become an integral part of the ensemble, enclosing the Theatre Square from the sea side. Subsequently, the construction of the Palais Royal was not fully completed and the Theatre Square itself was not given a formal framing.

The English Club managed to be built, but it remained a free-standing building, removed from the context of Torricelli's plans, but well in harmony with its historic architectural surroundings of the Classicist period.

The style of the building is executed in the canons of early eclecticism and combines elements of classicism and empire as well as obvious Renaissance features. Located on a sloping relief, the building has a variable number of floors from one floor on the side of the Theater Gardens to one and a half floors on the side of the Duma Square.`,
    textUkrainian: `Витончена півтораповерхова будівля Англійського клубу належить до пізньої творчості Торічеллі і проєктувалася ним у 1840-ті рр. паралельно з будівництвом комплексу крамниць Пале-Рояля по сусідству. 

Фактично Англійський клуб мав стати невід'ємною частиною цього ансамблю, замикаючи Театральну площу з боку моря. Згодом будівництво Пале-Рояля повною мірою не було завершене і сама театральна площа не отримала парадного обрамлення.

Англійський клуб встигли побудувати, але він залишився окремою будівлею, вирваною з контексту задумів Торічеллі, але добре гармонує зі своїм історичним архітектурним оточенням періоду класицизму.

Стиль будівлі виконаний у канонах ранньої еклектики і поєднує в собі як елементи класицизму й ампіру, так і явні ренесансні риси. Розташовуючись на похилому рельєфі, будівля має змінну поверховість від одного поверху з боку Театрального саду до півтора поверхів з боку Думської площі.`,
    textDeutsch: `Das zierliche eineinhalbstöckige Gebäude des Englischen Clubs gehört der späten Schaffensperiode von Torricelli an. Er entwarf dieses Haus in den 1840-er Jahren gleichzeitig mit dem Bau der Läden von Palais-Royal in der Nähe. Der Englische Club sollte ein unabdingbarer Bestandteil dieses Ensembles werden und den Theaterplatz von der Meeresseite abschließen. Der Bau des Palais-Royal wurde nicht beendet, und der Theaterplatz erhielt keine Paradenumrahmung.

Der Englische Club konnte fertig gebaut werden; er blieb jedoch als Einzelgebäude stehen, das aus dem Konzept von Toricelli weggerissen wurde. Das Gebäude schafft jedoch Harmonie mit seiner historischen Bauumgebung aus der Zeit des Klassizismus.

 Das Gebäude wurde im Stil des früheren Eklektizismus gebaut: Es verbindet Elemente von Klassizismus und Empire und weist klare Renaissancezüge aus. Das Haus liegt in einer geneigten Landschaft und hat unterschiedliche Etagenzahl: eine Etage vonseiten des Theatergartens und eineinhalb Etagen vonseiten des Duma-Platzes.   
`,
  },
  {
    id: 'Torricelli',
    author: 'Торичелли Георгий Иванович',
    authorUkrainian: 'Торічеллі Георгій Іванович',
    authorEnglish: 'Torricelli Georgiy',
    authorDeutsch: 'Torricelli Georgiy',
    projectName: 'Дом Калио (Камо)',
    projectNameUkrainian: 'Будинок Каліо (Камо)',
    projectNameEnglish: 'Calyo House (Camo)',
    projectNameDeutsch: 'Das Haus von Luka Kalio',
    photos: [Kamo1, Kamo2],
    text: `Дом Калио (Камо) — выдающийся образец одесского ампира и один из наиболее ярких домов авторства архитектора Торичелли. На момент строительства в 1834 г. дом располагался на тогдашней окраине города и его массивный призматический объем, занимающий ныне угол Тираспольской и Нежинской на фоне малоэтажной застройки того времени выглядел особенно внушительно.

Элегантно оформленное здание ценится своим интерьером с трехпролетной парадной лестницей и полуротондой зала на втором этаже.

В 1893 г. здесь разместилась второклассная гостиница Марсель, хорошо известная многим одесситам предреволюционных лет и порой упоминавшаяся в литературе
`,
    textEnglish: `The Caglio (Kamo) House is an outstanding example of the Odessa Empire style and one of the most striking houses designed by architect Torricelli. At the time of its construction in 1834 the house was located on the outskirts of the city and its massive prismatic volume, now occupying the corner of Tiraspolskaya and Nizhynskaya, against the background of low-rise buildings of the time looked especially imposing.

The elegantly decorated building is appreciated for its interior with a three-bay front staircase and a half-rotunda of a hall on the second floor.

In 1893 it housed a second-class hotel Marcel, well known to many Odessans in the pre-revolutionary years and sometimes mentioned in the literature.`,
    textUkrainian: `Будинок Каліо (Камо) - видатний зразок одеського ампіру і один з найяскравіших будинків авторства архітектора Торічеллі. На момент будівництва 1834 р. будинок розташовувався на тодішній околиці міста і його масивний призматичний об'єм, що займає нині ріг Тираспольської та Ніжинської, на тлі малоповерхової забудови того часу мав особливо переконливий вигляд.

Елегантно оформлена будівля цінується своїм інтер'єром із трипролітними парадними сходами і напівротондою залу на другому поверсі.

У 1893 р. тут розмістився другокласний готель "Марсель", добре відомий багатьом одеситам передреволюційних років, про який деколи згадували в літературі.`,
    textDeutsch:
      'Das Haus von Kalio (Kamo) ist ein herausragendes Beispiel für den Empirestil in Odesa und eines der schönsten Häuser des Architekten Torricelli. Zur Zeit seiner Errichtung 1834 lag das Haus am damaligen Stadtrand. Das massive prismatische Volumen dieses Gebäudes, das heute an der Kreuzung der Straßen Tiraspolska und Nizhynska liegt, sah vor dem Hintergrund der damaligen niedrigen Bebauung besonders monumental aus. Das elegant gestaltete Gebäude beherbergt prachtvolle Inneneinrichtung mit einer Paradentreppe und einer Halbrotonde im ersten Obergeschoss. 1893 befand sich hier das Hotel der 2. Klasse „Marseille“, das vielen Einwohnern Odesas in der vorrevolutionären Zeit gut bekannt war und gelegentlich in der Literatur erwähnt wurde.',
  },
  {
    id: 'Torricelli',
    author: 'Торичелли Георгий Иванович',
    authorUkrainian: 'Торічеллі Георгій Іванович',
    authorEnglish: 'Torricelli Georgiy',
    authorDeutsch: 'Torricelli Georgiy',
    projectName: 'Дом Нарольского',
    projectNameUkrainian: 'Будинок Нарольського',
    projectNameEnglish: 'Narolsky House',
    projectNameDeutsch: 'Das Haus von Narolskyi',
    photos: [Narolskiy1, Narolskiy2, Narolskiy3, Narolskiy4],
    text: `Дом Нарольского важен не только, как этап творчества Торичелли в начале 1830-х гг., но и как один из самых ярких образцов городского особняка Одессы того времени.

Здание выполнено в редком для города стиле палладианства. Специфика английской классической школы прослеживается в подчеркнуто монументальных порталах на фоне геометричных наличников окон и полного отсутствия лепных элементов на фасаде.

Самая яркая часть дома — парадная лестница, решенная в виде непрерывной скругленной дуги с первого этажа на второй. Освещение решено уникальным для светских построек способом — фонарь выполнен в виде купольного барабана с периметральным доступом света. Такая система, традиционная для православных церквей, в простом жилом доме применена единожды за всю историю города.
`,
    textEnglish: `The Narolsky House is important not only as a stage of Toricelli's work in the early 1830s, but also as one of the most striking examples of Odessa's urban mansion of the time.

The building is designed in the Palladian style, rare in the city. Specificity of English classical school is traced in stressed monumental portals on the background of geometrical window platbands and total absence of stucco elements on the facade.

The most striking part of the house is the main staircase which is designed in the form of a continuous rounded arc from the first floor to the first floor. Lighting is solved in a way that is unique for secular buildings - the lantern is made in the form of a dome drum with a perimeter access of light. Such a system, traditional for Orthodox churches, is used in a simple apartment building only once in the history of the city.`,
    textUkrainian: `Будинок Нарольського важливий не тільки як етап творчості Торічеллі на початку 1830-х рр., а й як один з найяскравіших зразків міського особняка Одеси того часу.

Будівля виконана в рідкісному для міста стилі палладіанства. Специфіка англійської класичної школи простежується в підкреслено монументальних порталах на тлі геометричних лиштв вікон і повної відсутності ліпних елементів на фасаді.

Найяскравіша частина будинку - парадні сходи, вирішені у вигляді безперервної округленої дуги з першого поверху на другий. Освітлення вирішено унікальним для світських будівель способом - ліхтар виконаний у вигляді купольного барабана з периметральним доступом світла. Така система, традиційна для православних церков, у простому житловому будинку застосована один раз за всю історію міста.`,
    textDeutsch: `Das Haus von Narolskyi markiert eine Etappe im Schaffen von Torricelli Anfang der 1830-er Jahre und ist eines der schönsten Beispiele für eine Stadtvilla im damaligen Odesa. Das Gebäude ist in dem Palladio-Stil gebaut, der in der Stadt selten zu treffen ist. Die Eigenart der englischen klassizistischen Schule erkennt man an den monumentalen Portalen und geometrischen Fensterverkleidungen sowie an fehlenden der Stuckelementen an der Fassade. 

    Das schönste Element ist die Paradentreppe, die in Form eines ununterbrochenen gerundeten Bogens vom Erdgeschoß zum ersten Obergeschoß führt. Die Beleuchtung ist ebenfalls für die Profanarchitektur einmalig: Die Laterne ist in Form eines Kuppeltrommels mit dem Lichtzugang gebaut. Dieses System ist traditionell für orthodoxe Kirchen. In einem einfachen Wohnhaus kommt es nur einmal in der ganzen Stadtgeschichte vor.   
`,
  },
  {
    id: 'Torricelli',
    author: 'Торичелли Георгий Иванович',
    authorUkrainian: 'Торічеллі Георгій Іванович',
    authorEnglish: 'Torricelli Georgiy',
    authorDeutsch: 'Torricelli Georgiy',
    projectName: 'Дом Хорвата (Толстых)',
    projectNameUkrainian: 'Будинок Хорвата (Толстих)',
    projectNameEnglish: 'House of Horvath (Tolstoy',
    projectNameDeutsch: 'Das Haus von Khorvat / Tolstois',
    photos: [Tolstoy1, Tolstoy2, Tolstoy3, Tolstoy4],
    text: `Усадьба Толстых — архитектурный комплекс, который включает в себя 3 здания: двухэтажный особняк с 12 комнатами, служебное крыло и картинную галерею. 

Особняк в стиле русского классицизма, выходящий фасадом на Военный спуск, построили в 1830-х архитекторы Боффо и Торичелли для камер-юнкера Николая Хорвата. 

В 60-х годах XIX в. усадьба переходит во владение Греческого Генерального консула Цицинии, а позднее дом был выкуплен графами Толстыми, которые проживали здесь до самой революции, попутно благоустраивая и достраивая весь комплекс.

Бывший особняк Хорвата — классический пример богатого аристократического дома Одессы первой половины XIX в. Необычным решением служит ориентация здания основным фасадом в сторону сада, а торцевым, второстепенным — к линии улицы.

Степень сохранности и аутентичности дома сегодня можно считать самой высокой в Одессе.

С 1930-х гг. здесь располагается Одесский Дом Ученых.
`,
    textEnglish: `Tolstoy Manor is an architectural complex, which includes 3 buildings: a two-storey mansion with 12 rooms, a service wing and an art gallery. 

The mansion in the style of Russian classicism overlooking the Military Descent was built in 1830s by architects Boffo and Toricelli for chamber-master Nikolai Horvat. 

In the 1860s the estate came into possession of the Greek Consul General Tsitsinia, and later the house was bought by Counts Tolstoy, who lived here until the revolution, simultaneously improving and completing the entire complex.

Former Horvath mansion is a classic example of rich aristocratic house of Odessa of first half of 19th century. The orientation of the building with the main facade to the garden side and with the side, secondary facade, to the street line is an unusual decision.

The degree of preservation and authenticity of the house today can be considered the highest in Odessa.

Since the 1930s, it has been home to the Odessa House of Scientists`,
    textUkrainian: `Садиба Толстих - архітектурний комплекс, що складається з 3 будівель: двоповерхового особняка з 12 кімнатами, службового крила і картинної галереї. 

Особняк у стилі російського класицизму, що виходить фасадом на Військовий узвіз, побудували в 1830-х архітектори Боффо і Торічеллі для камер-юнкера Миколи Хорвата. 

У 60-х роках XIX ст. садиба переходить у володіння Грецького Генерального консула Цицинії, а пізніше будинок викупили графи Толсті, які проживали тут аж до самої революції, попутно упорядковуючи і добудовуючи весь комплекс.

Колишній особняк Хорвата - класичний приклад багатого аристократичного будинку Одеси першої половини XIX ст. Незвичайним рішенням служить орієнтація будівлі основним фасадом у бік саду, а торцевим, другорядним - до лінії вулиці.

Ступінь збереженості й автентичності будинку сьогодні можна вважати найвищим в Одесі.

З 1930-х рр. тут розташовується Одеський Будинок учених.`,
    textDeutsch: `Das Haus von Tolstois ist ein Architekturensemble, das sich aus drei Gebäuden zusammensetzt: einem zweistöckigen Haus mit 12 Zimmern, einem Dienerflügel und einer Gemäldegalerie.   

Das Haus, dessen Fassade sich auf den Militärsteig richtet, wurde in den 1830-er Jahren von den Architekten Boffo und Torricelli für den Kammerjunker Nikolai Khorvat im Stil des russischen Klassizismus gebaut.

In den 1860-er Jahren befand sich das Haus im Besitz des griechischen Generalkonsuls Cicini; Später kaufte das Haus die Familie des Grafen Tolstoi, die hier bis zur Revolution wohnte und den ganzen Gebäudekomplex weiter ausbauten. 

Das ehemalige Haus von Khorvat ist ein klassisches Beispiel für das Wohnhaus eines reichen Adels von Odesa in der ersten Hälfte des 19. Jh. Eine ungewöhnliche Lösung stellt die Orientierung des Hauses mit der Hauptfassade zum Garten dar, wobei die Seitenfassade zur Straße gewendet ist. Dieses Haus blieb sehr gut erhalten und hat den besten Erhaltungsgrad in Odesa. Seit 1930-er Jahren befindet sich hier das Haus der Wissenschaftler von Odesa. 
`,
  },
];
